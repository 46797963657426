/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    circle: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 15A7 7 0 118 1a7 7 0 010 14m0 1A8 8 0 108 0a8 8 0 000 16"/>',
    },
});
